<template>
  <transition name="slide-fade" mode="out-in">
    <div class="login">
      <div class="logo"></div>
      <h5 class="text-center">Login</h5>
      <form @submit.prevent="onLogin">
        <input
          @change="reset"
          type="email"
          required
          placeholder="Email"
          v-model="user.emailAddress"
        />
        <input
          required
          type="password"
          @change="reset"
          placeholder="Password"
          v-model="user.password"
        />

        <button :disabled="loading" class="btn auth-btn" @click="onLogin">
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span v-show="!loading">Login</span>
        </button>
      </form>
      <router-link style="float: right" to="/auth/forgot"
        >Forgot password?</router-link
      >
      <div class="error-container">
        <transition name="slide-fade" mode="out-in">
          <p v-show="error" class="error">{{ error }}</p>
        </transition>
      </div>
    </div>
  </transition>
</template>
<script>
import ikebana from "../../services/ikebana";
export default {
  data() {
    return {
      loading: false,
      user: {
        emailAddress: "",
        password: "",
      },
      error: null,
    };
  },
  methods: {
    reset() {
      this.error = null;
    },
    async onLogin() {
      this.loading = true;
      try {
        ikebana.login(this.user).then(
          (response) => {
            this.loading = false;
            this.$store.commit("SET_USER", response.data);
            this.$store.dispatch("LOAD_DATA");
            this.$router.push("/order/");
          },
          (error) => {
            this.error = error.response.data.Message;
            this.loading = false;
          }
        );
      } catch (error) {
        this.loading = false;
        this.$notify({
          duration: 5000,
          group: "error",
          title: "Important message",
          text: error.response.data.Message,
        });
      }
    },
  },
  mounted: function () {},
};
</script>
<style lang="css" scoped>
.error-container {
  height: 100px;
  min-height: 100px;
}
.error {
  color: red;
  text-align: center;
  margin-top: 50px;
}
@media (max-width: 768px) {
  .login {
    width: 90vw !important;
    min-height: 80vh !important;
    padding: 20px !important;
  }

  .auth-btn {
    bottom: 0;
    left: 0;
    margin-bottom: 10px;
    margin-left: 10px;
    height: 50px;
    position: absolute;
    width: 85vw !important;
  }
  form {
    margin-top: 70px;
  }
}
.auth-btn {
  width: 100%;
  color: white;
  border-radius: 12px;
  background: #b52025;
}
a {
  text-decoration: none;
  text-align: center;
  color: #707070;
}
.login {
  background: white;
  border-radius: 15px;
  position: absolute;
  width: 40vw;
  left: 50%;
  align-content: center;
  justify-content: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 20px 50px 20px 50px;
}

.login input {
  margin-bottom: 10px;
}

input {
  width: 100% !important;
  height: 50px;
}

.logo {
  height: 100px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background: url("../../assets/logo.png") center center no-repeat;
}
</style>
